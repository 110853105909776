import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import Bookmark from 'components/Bookmark';
import CardMenu from 'components/CardMenu';
import CardWrapper from 'components/CardWrapper';
import EditableCard from 'components/EditableCard/EditableCard';
import ErrorMessage from 'components/ErrorMessage';
import FilePreview from 'components/FilePreview';
import Form from 'components/Form';
import FormChangeHandler from 'components/FormChangeHandler/FormChangeHandler';
import FormTagList from 'components/FormTagList';
import Input from 'components/Inputs/Input';
import Textarea from 'components/Inputs/Textarea';
import ColumnWrapper from 'components/Layout/ColumnWrapper';
import ContentWrapper from 'components/Layout/ContentWrapper';
import FileWrapper from 'components/Layout/FileWrapper';
import PositionWrapper from 'components/Layout/PositionWrapper/PositionWrapper';
import RowWrapper from 'components/Layout/RowWrapper';
import Lightbox from 'components/Lightbox';
import LinkRow from 'components/LinkRow';
import Loading from 'components/Loading';
import MainButton from 'components/MainButton';
import PageHeader from 'components/PageHeader';
import PageLogoHeader from 'components/PageLogoHeader';
import ProjectTopicPopup from 'components/ProjectTopicPopup';
import SectionHeadline from 'components/SectionHeadline';
import TagList from 'components/TagList';
import UploadButton from 'components/UploadButton';
import UploadedImage from 'components/UploadedImage';
import CommentsContainer from 'containers/CommentsContainer/CommentsContainer';
import UploadContainer from 'containers/UploadContainer';
import {
  UploadContainerChildProps,
  UploadContainerStateEnum
} from 'containers/UploadContainer/UploadContainer';
import UploadedFileContainer from 'containers/UploadContainer/UploadedFileContainer';
import { ElementType } from 'models/ApiElementTypeEnum';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { AttachmentModelType } from 'models/AttachmentModel';
import { BriefingStoreType } from 'models/BriefingStore';
import { DataStoreType } from 'models/DataStore';
import { tagsListToArray } from 'models/TagListModel';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import { PainpointsStoreType } from 'models/PainpointsStore';

interface PublicBriefingDetailContainerProps extends WrappedComponentProps {
  edit?: boolean;
}

interface BriefingDetailContainerProps
  extends PublicBriefingDetailContainerProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  briefingStore: BriefingStoreType;
  painpointsStore: PainpointsStoreType;
  form: FormType;
}
interface BriefingDetailContainerState {
  lightboxOpen: boolean;
  lightboxSource?: string;
  projectPopupVisible: boolean;
}

@inject('applicationStore', 'dataStore', 'briefingStore', 'painpointsStore')
@observer
class BriefingDetailContainer extends React.Component<
  BriefingDetailContainerProps & HistoryProps,
  BriefingDetailContainerState
> {
  state: BriefingDetailContainerState = {
    lightboxOpen: false,
    lightboxSource: undefined,
    projectPopupVisible: false
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    const { form } = this.props;
    form.reset();

    const briefing = await this.props.briefingStore.getBriefing();

    if (briefing) {
      if (this.props.edit) {
        form.setField('question', briefing.question);
        form.setField('description', briefing.description);
      }
      form.setField('tags', tagsListToArray(briefing.tags));
    }
  }

  async save(saveButton = false) {
    const { briefingStore, form } = this.props;

    // TODO update form after update?
    const updatedBriefing = await briefingStore.createOrUpdateBriefing({
      question: form.values.question || '',
      description: form.values.description || '',
      tags: form.values.tags || undefined
    });

    if (saveButton) {
      if (briefingStore.isItemSaveError) {
        this.displayError();
      } else if (updatedBriefing) {
        this.props.history.replace(this.props.dataStore.contextUri);
      }
    }
  }

  async updateTags(newTags?: string[]) {
    const { briefingStore } = this.props;

    await briefingStore.createOrUpdateBriefing({
      tags: newTags
    });
  }

  uploadLoadingStateChanged(newState?: UploadContainerStateEnum) {
    const { form, dataStore } = this.props;

    if (!newState) {
      form.setLoading(false);
      return;
    }

    if (newState === 'saving' && !dataStore.briefing?.id) {
      // item has not been saved yet, so we need to wait for the upload before we can reenable form
      form.setLoading(true);
    }
  }

  attachmentReceived(attachment: AttachmentModelType) {
    const { briefingStore, dataStore } = this.props;

    if (!attachment) {
      return;
    }

    if (dataStore.briefing) {
      briefingStore.addAttachment(attachment);
    } else if (attachment.element_id) {
      briefingStore.initializeItem({
        id: attachment.element_id,
        attachments: [attachment]
      });
    }
  }

  attachmentRemoved(id: number) {
    this.props.briefingStore.removeAttachment(id);
  }

  async saveFieldUpdate(names: string[]) {
    const { form, briefingStore, dataStore } = this.props;

    if (!dataStore.briefing) {
      return;
    }

    const patch: any = {};
    names.forEach((name) => {
      patch[name] = form.values[name] || '';
    });

    try {
      await briefingStore.createOrUpdateBriefing(patch);

      if (briefingStore.isItemSaveError) {
        this.displayError();
        return;
      }

      form.setEditing();
    } catch (error) {
      handleFormError(form, error);
    }
  }

  displayError(id = 'save') {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: id + ' error flash' }),
      'error'
    );
  }

  displayLightbox(url?: string) {
    if (!url) {
      return;
    }

    this.setState({
      lightboxOpen: !this.state.lightboxOpen,
      lightboxSource: url
    });
  }

  displayProjectPopup() {
    this.setState({
      projectPopupVisible: true
    });
  }

  hideProjectPopup() {
    this.setState({
      projectPopupVisible: false
    });
  }

  maybeRenderProjectPopup() {
    if (!this.state.projectPopupVisible) {
      return null;
    }

    return <ProjectTopicPopup onClose={() => this.hideProjectPopup()} />;
  }

  renderPage(content: any, mayEdit?: boolean) {
    return (
      <>
        <PageHeader
          titleId="Briefing"
          logoHeader={
            // tslint:disable-next-line: jsx-wrap-multiline
            <PageLogoHeader
              title={this.props.dataStore.project?.topic}
              strategyType={this.props.dataStore.project?.gpt_strategy_type}
              onEditClick={
                !mayEdit ? undefined : () => this.displayProjectPopup()
              }
            />
          }
        />
        {content}
      </>
    );
  }

  renderLoading() {
    const { itemLoadingState } = this.props.briefingStore;
    const { isPrefillingPainPointClusters } = this.props.applicationStore;

    if (itemLoadingState === 'loading' && !isPrefillingPainPointClusters) {
      return this.renderPage(<Loading />);
    }
    return null;
  }

  renderError() {
    const { itemLoadingState } = this.props.briefingStore;
    return this.renderPage(
      <ErrorMessage state={itemLoadingState} onRetry={() => this.load()} />
    );
  }

  renderNotFound() {
    const { dataStore } = this.props;

    return this.renderPage(
      <ContentWrapper>
        <ColumnWrapper gap="2em">
          <CardWrapper>
            <RowWrapper>
              <FormattedMessage id="briefing not found" />
            </RowWrapper>
            {dataStore.isOrgAdmin && (
              <PositionWrapper end={true}>
                <MainButton
                  type="button"
                  onClick={() =>
                    this.props.history.push(
                      dataStore.currentProjectId + '/edit'
                    )
                  }
                >
                  <FormattedMessage id="Create briefing" />
                </MainButton>
              </PositionWrapper>
            )}
          </CardWrapper>
        </ColumnWrapper>
      </ContentWrapper>
    );
  }

  renderEditMode() {
    const { form, briefingStore, dataStore, intl } = this.props;

    const item = dataStore.briefing;
    return (
      <>
        <FormChangeHandler
          onSave={() => this.save()}
          isSaving={briefingStore.isItemSaving}
        >
          {({ onTextChanged, hasUnsavedChanges }) => (
            <Form loading={form.loading} onSubmit={() => this.save(true)}>
              <PageHeader titleId="Briefing" />
              <ContentWrapper>
                <FormTagList
                  form={form}
                  field="tags"
                  allowAdd={true}
                  onChanged={onTextChanged}
                />

                <ColumnWrapper gap="2em">
                  {briefingStore.isItemSaveError && (
                    <ErrorMessage
                      state={briefingStore.itemLoadingState}
                      onRetry={() => this.save()}
                    />
                  )}

                  <CardWrapper>
                    <Input
                      name="question"
                      label={<FormattedMessage id="briefing question" />}
                      placeholder={intl.formatMessage({
                        id: 'briefing question placeholder'
                      })}
                      {...form.bindInput('question', onTextChanged)}
                    />
                  </CardWrapper>

                  <CardWrapper>
                    <Textarea
                      name="description"
                      label={<FormattedMessage id="Short description" />}
                      {...form.bindInput('description', onTextChanged)}
                    />
                  </CardWrapper>

                  <FileWrapper>
                    {item &&
                      item.attachments &&
                      item.attachments.hasAny &&
                      item.attachments.all.map((attachment) => (
                        <UploadedFileContainer
                          attachment={attachment}
                          onAttachmentRemoved={(id) =>
                            this.attachmentRemoved(id)
                          }
                          key={attachment.id}
                        >
                          {(props) => <UploadedImage {...props} />}
                        </UploadedFileContainer>
                      ))}

                    <UploadContainer
                      attachmentType="image"
                      elementType={ElementType.Briefing}
                      elementId={dataStore.briefing?.id}
                      waitForId={
                        hasUnsavedChanges || briefingStore.isItemSaving
                      }
                      onLoadingStateChanged={(s) =>
                        this.uploadLoadingStateChanged(s)
                      }
                      onAttachmentReceived={(a) => this.attachmentReceived(a)}
                    >
                      {(props: UploadContainerChildProps) => (
                        <UploadButton {...props} />
                      )}
                    </UploadContainer>
                  </FileWrapper>

                  <PositionWrapper center={true}>
                    <MainButton type="submit">
                      {!hasUnsavedChanges && !briefingStore.isItemSaving && (
                        <FormattedMessage id="Save" />
                      )}
                      {hasUnsavedChanges && '(unsaved changes)'}
                      {briefingStore.isItemSaving && ' saving...'}
                    </MainButton>
                  </PositionWrapper>
                </ColumnWrapper>
              </ContentWrapper>
            </Form>
          )}
        </FormChangeHandler>
      </>
    );
  }

  renderViewMode() {
    const { dataStore, briefingStore, form, intl } = this.props;

    const item = dataStore.briefing;
    if (!item) {
      return null;
    }

    const mayEdit = dataStore.mayEdit(item.author);
    const hasAttachments = item && item.attachments && item.attachments.hasAny;

    return this.renderPage(
      <>
        <ContentWrapper>
          {mayEdit ? (
            <FormTagList
              form={this.props.form}
              field="tags"
              allowAdd={true}
              onChanged={(t) => this.updateTags(t)}
            />
          ) : (
            <TagList tags={item.tags} />
          )}

          {this.maybeRenderProjectPopup()}
          {briefingStore.isItemLoading && <Loading />}

          <ColumnWrapper gap="3em">
            <LinkRow
              links={[
                {
                  isLink: false,
                  to: '',
                  content: intl.formatMessage({ id: 'Briefing' })
                },
                {
                  isLink: true,
                  to: dataStore.contextUri + '/inspiration',
                  content: intl.formatMessage({ id: 'Learnings' })
                }
              ]}
            />
            <ColumnWrapper gap="0.625em">
              <EditableCard
                shrink={true}
                textSize="large"
                largeFont={true}
                singleLine={true}
                lines={5}
                form={form}
                name="question"
                label={<FormattedMessage id="question long" />}
                value={item.question}
                editable={mayEdit}
                onSave={() => this.saveFieldUpdate(['question'])}
              />

              <EditableCard
                form={form}
                name="description"
                simple={true}
                label={<FormattedMessage id="Briefing description" />}
                value={item.description}
                editable={mayEdit}
                onSave={() => this.saveFieldUpdate(['description'])}
                extra={
                  // tslint:disable-next-line: jsx-wrap-multiline
                  <CardMenu user={item.author}>
                    <Bookmark
                      elementType="Briefing"
                      elementId={item.id}
                      count={item.bookmarks_count}
                      id={item.bookmark_id}
                    />
                  </CardMenu>
                }
              />

              <div className="briefing-detail-row">
                <EditableCard
                  form={form}
                  shrink={true}
                  simple={true}
                  name="ai_target_group_description"
                  label={<FormattedMessage id="Briefing target description" />}
                  value={item.ai_target_group_description}
                  editable={mayEdit}
                  onSave={() =>
                    this.saveFieldUpdate(['ai_target_group_description'])
                  }
                />
                <EditableCard
                  form={form}
                  shrink={true}
                  simple={true}
                  name="ai_service_description"
                  label={<FormattedMessage id="Briefing service description" />}
                  value={item.ai_service_description}
                  editable={mayEdit}
                  onSave={() =>
                    this.saveFieldUpdate(['ai_service_description'])
                  }
                />
              </div>

              <div className="briefing-detail-row briefing-detail-row--one-third">
                <EditableCard
                  form={form}
                  shrink={true}
                  simple={true}
                  name="ai_business_target_description"
                  label={
                    <FormattedMessage id="Briefing business target description" />
                  }
                  value={item.ai_business_target_description}
                  editable={mayEdit}
                  onSave={() =>
                    this.saveFieldUpdate(['ai_business_target_description'])
                  }
                />
                <EditableCard
                  form={form}
                  shrink={true}
                  simple={true}
                  name="ai_characteristic_description"
                  label={
                    <FormattedMessage id="Briefing characteristic description" />
                  }
                  value={item.ai_characteristic_description}
                  editable={mayEdit}
                  onSave={() =>
                    this.saveFieldUpdate(['ai_characteristic_description'])
                  }
                />
              </div>
            </ColumnWrapper>

            <ColumnWrapper gap="0">
              {hasAttachments || mayEdit ? (
                <>
                  <SectionHeadline
                    title={<FormattedMessage id="additional info" />}
                    value={item.attachments.countAll}
                  />

                  {/* TODO: Fix Lightbox in mobile iOS Safari (ipad). */}
                  {/* https://www.kenst.com/2019/03/how-to-debug-problems-on-mobile-safari/ */}
                  <Lightbox
                    key={item.attachments.hash} // make lightbox rerender on each list change
                    toggler={this.state.lightboxOpen}
                    type="image"
                    sources={item.attachments.all.map(
                      (attachment) => attachment.resource_urls?.large
                    )}
                    source={this.state.lightboxSource}
                  />
                </>
              ) : null}

              {mayEdit ? (
                <FileWrapper>
                  {hasAttachments &&
                    item.attachments.all.map((attachment) => (
                      <UploadedFileContainer
                        key={attachment.id}
                        attachment={attachment}
                        onAttachmentRemoved={(id) => this.attachmentRemoved(id)}
                      >
                        {(props) => (
                          <UploadedImage
                            {...props}
                            onClick={() =>
                              this.displayLightbox(
                                attachment.resource_urls?.large
                              )
                            }
                          />
                        )}
                      </UploadedFileContainer>
                    ))}

                  <UploadContainer
                    attachmentType="image"
                    elementType={ElementType.Briefing}
                    elementId={item.id}
                    onLoadingStateChanged={(s) =>
                      this.uploadLoadingStateChanged(s)
                    }
                    onAttachmentReceived={(a) => this.attachmentReceived(a)}
                  >
                    {(props: UploadContainerChildProps) => (
                      <UploadButton {...props} />
                    )}
                  </UploadContainer>
                </FileWrapper>
              ) : (
                hasAttachments && (
                  <FileWrapper>
                    {item.attachments.all.map((attachment) => (
                      <FilePreview
                        key={attachment.id}
                        imgUrl={attachment.resource_urls?.large}
                        alt="Attachment"
                        onClick={() =>
                          this.displayLightbox(attachment.resource_urls?.large)
                        }
                      />
                    ))}
                  </FileWrapper>
                )
              )}
            </ColumnWrapper>

            <CommentsContainer
              elementType={ElementType.Briefing}
              element={item}
            />
          </ColumnWrapper>
        </ContentWrapper>
      </>,
      mayEdit
    );
  }

  render() {
    const { briefingStore, edit } = this.props;

    if (briefingStore.isItemLoading) {
      return this.renderLoading();
    }

    if (briefingStore.isItemLoadError) {
      return this.renderError();
    }

    if (edit) {
      return this.renderEditMode();
    }

    if (briefingStore.isItemNotFound) {
      // TODO do something more useful - redirect to form if admin?
      return this.renderNotFound();
    }

    return this.renderViewMode();
  }
}

export default injectIntl((props: PublicBriefingDetailContainerProps) => {
  const form = useForm();
  // @ts-ignore
  return <BriefingDetailContainer {...props} form={form} />;
});
