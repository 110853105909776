import Form from 'components/Form';
import Input from 'components/Inputs/Input';
import SimplePopup from 'components/SimplePopup';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { DataStoreType } from 'models/DataStore';
import { ProjectsStoreType } from 'models/ProjectsStore';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface PublicProjectTopicPopupProps extends WrappedComponentProps {
  onClose?: () => void;
}

interface ProjectTopicPopupProps extends PublicProjectTopicPopupProps {
  applicationStore: ApplicationStoreType;
  dataStore: DataStoreType;
  projectsStore: ProjectsStoreType;
  form: FormType;
}

@inject('applicationStore', 'dataStore', 'projectsStore')
@observer
class ProjectTopicPopup extends React.Component<ProjectTopicPopupProps> {
  componentDidMount() {
    this.props.form.setField(
      'topic',
      this.props.dataStore.currentProject?.topic || ''
    );
  }

  async save() {
    const { dataStore, projectsStore, form, onClose } = this.props;

    if (!dataStore.currentProjectId) {
      return;
    }

    form.resetErrors();

    const patch: any = {
      topic: form.values.topic || ''
    };

    try {
      await projectsStore.updateProject(
        dataStore.currentProjectId,
        patch,
        dataStore.currentOrganizationId
      );
    } catch (error: any) {
      if (handleFormError(form, error)) {
        return;
      }
    }

    if (projectsStore.isItemSaveError) {
      this.displayError();
      return;
    }

    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: 'save success flash' })
    );

    onClose && onClose();
  }

  displayError() {
    this.props.applicationStore.setFlashMessage(
      this.props.intl.formatMessage({ id: 'save error flash' }),
      'error'
    );
  }

  render() {
    const { form, projectsStore } = this.props;

    return (
      <Form disabled={projectsStore.isItemLoading}>
        <SimplePopup
          isLoading={projectsStore.isItemLoading}
          onSubmit={() => this.save()}
          onAbort={this.props.onClose}
        >
          <Input
            type="text"
            gap={true}
            name="topic"
            autoFocus={true}
            label={<FormattedMessage id="Project topic" />}
            {...form.bindInput('topic')}
          />
        </SimplePopup>
      </Form>
    );
  }
}

export default injectIntl((props: PublicProjectTopicPopupProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProjectTopicPopup {...props} form={form} />;
});
